import ReactDOM from 'react-dom/client'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'

import './index.css'
import App from './App'
import { store } from './store'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
    />
  </React.StrictMode>
)
