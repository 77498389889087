import React from 'react'

type TellitCardProps = {
  children: React.ReactNode
  borderColor?: string
  bg?: string
  border?: string
  borderRadius?: string
  customClass?: string
}

const TellitCard = ({
  children,
  borderColor = 'border-white',
  bg = 'bg-whiteLight',
  border = 'border',
  borderRadius = 'rounded-[20px]',
  customClass
}: TellitCardProps): JSX.Element => {
  return (
    <div
      className={`${border} ${borderColor} ${borderRadius} px-[30px] py-[26px] xl:px-[50px] xl:py-[40px] w-auto card-min-width 2xl:min-w-[810px] h-auto shadow-sm  ${bg} ${customClass}`}
    >
      {children}
    </div>
  )
}

export default TellitCard
