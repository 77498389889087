import React, { useState } from 'react'
import { BackBtn, Header, NextBtn } from './subComponents'
import TellitButton from 'components/common/TellitButton'
import TellitInput from 'components/form/TellitInput'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import {
  NavigateBackward,
  NavigateForward,
  UpdateUserValue
} from 'store/userSetup/action'
import { useTranslation } from 'react-i18next'
import { FiUserPlus } from 'react-icons/fi'
import UseValidateForm, { type IErrorObj } from 'utils/useFormValidate'
import useErrorArray from 'utils/useErrorArray'

const StepFour = (): JSX.Element => {
  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])
  const { t } = useTranslation()
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )

  const handleMultipleAdd = (): void => {
    if (formValues.users.length < 3) {
      const updatedFormValues = [...formValues.users]
      updatedFormValues.push({
        firstName: '',
        lastName: '',
        email: '',
        mobile: ''
      })
      dispatch(UpdateUserValue({ name: 'users', value: updatedFormValues }))
    }
  }

  const handleUserMultiple = ({
    e,
    idx
  }: {
    e: React.ChangeEvent<HTMLInputElement>
    idx: number
  }): void => {
    const updatedFormValues = [...formValues.users]
    if (idx >= 0 && idx < updatedFormValues.length) {
      const updatedUser = { ...updatedFormValues[idx] }
      updatedUser[e.target.name as keyof typeof updatedUser] = e.target.value
      updatedFormValues[idx] = updatedUser
      dispatch(UpdateUserValue({ name: 'users', value: updatedFormValues }))
    }
  }

  const userAddMultipleForm = ({
    type,
    rowId,
    validationType
  }: {
    type: string
    rowId: number
    validationType?: string
  }): JSX.Element => {
    const label =
      type === 'firstName'
        ? t('StepFour.Form.FirstName.Label')
        : type === 'lastName'
        ? t('StepFour.Form.LastName.Label')
        : type === 'email'
        ? t('StepFour.Form.Email.Label')
        : t('StepFour.Form.Mobile.Label')

    const inputType = type === 'email' ? 'email' : 'text'
    return (
      <TellitInput
        label={label}
        id={type}
        name={type}
        value={
          formValues?.users[rowId][
            type as keyof (typeof formValues)['users'][0]
          ]
        }
        alignCenter
        type={inputType}
        onChange={(e) => {
          handleUserMultiple({ e, idx: rowId })
        }}
        customClass="w-sp300 step-four-input"
        enableErrorMsg
        formValues={formValues}
        validationType={validationType}
        setError={(val) => {
          const isFormError = UseValidateForm({
            fieldError: val,
            errorArray,
            name: `${type}${rowId}`,
            setErrorArray
          })
          setIsError(isFormError)
        }}
        errorMsgClass="!mb-0"
      />
    )
  }

  useErrorArray({
    errorArray,
    setErrorArray,
    setIsError,
    step: 4
  })

  const handleDisable = (): boolean => {
    if (isError) {
      return true // If there's an error, disable
    }

    if (formValues.users.length === 0) {
      return false // If there are no users, don't disable
    }

    // Iterate through each user object
    for (const user of formValues.users) {
      const isAllFieldsEmpty = Object.values(user).every(
        (value) => value.trim() === ''
      )
      const isAtLeastOneFieldEmpty = Object.values(user).some(
        (value) => value.trim() === ''
      )

      if (!isAllFieldsEmpty && isAtLeastOneFieldEmpty) {
        return true // Disable if at least one user has at least one field empty
      }
    }

    return false // If no user has at least one field empty (except when all fields are empty), don't disable
  }

  return (
    <>
      <Header className="md:px-20 2xl:px-sp120 lg-space font-medium 2xl:mt-[-8px]">
        {t('StepFour.Card.Description')}
      </Header>

      {formValues.users?.length !== 0 && (
        <div className="flex flex-col overflow-x-hidden 2xl:mt-[-30px]">
          {formValues.users.map((_, idx) => (
            <div
              key={`${idx}_user`}
              className="flex flex-col gap-4 items-center border-b border-1 border-neutral/40 pb-8 pt-4 snap-start margin-inline"
            >
              <Header className="text-base !font-semibold">{`Kollega ${
                idx + 1
              }`}</Header>

              <div className="flex flex-col  md:flex-row gap-4 lg:flex-col xl:flex-row items-center 2xl:items-start">
                {userAddMultipleForm({
                  type: 'mobile',
                  rowId: idx,
                  validationType: 'mobile'
                })}
                {userAddMultipleForm({
                  type: 'email',
                  rowId: idx,
                  validationType: 'email'
                })}
              </div>
              <div className="flex flex-col md:flex-row gap-4 items-center  lg:flex-col xl:flex-row">
                {userAddMultipleForm({
                  type: 'firstName',
                  rowId: idx
                })}
                {userAddMultipleForm({
                  type: 'lastName',
                  rowId: idx
                })}
              </div>
            </div>
          ))}
        </div>
      )}

      <TellitButton
        label={t('StepFour.Card.Button.AddUser')}
        icon={<FiUserPlus className="text-[1.2rem]" />}
        disabled={formValues.users.length >= 3}
        onClick={() => {
          handleMultipleAdd()
        }}
        customClass="max-h-[48px] xl:!min-w-[240px] tracking-normal text-[16px] font-normal 2xl:mt-[2px] 2xl:mb-[38px] 2xl:mt-[-23px] text-white w-sp224 mx-auto hover:shadow-md flex-row-reverse text-base-20 font-semibold !bg-neutral btn-sm-text tracking-wide text-[1rem]"
      />
      <div className="self-center mt-8 2xl:mt-[97px] flex justify-between 2xl:justify-center 2xl:gap-[60px] flex-col md:flex-row md:px-sp150 flex-wrap items-center w-full  lg:px-sp50 2xl:px-sp120 footer-nav">
        <BackBtn
          handleClick={() => {
            dispatch(NavigateBackward())
          }}
        />
        <NextBtn
          handleClick={() => {
            dispatch(NavigateForward())
          }}
          disabled={handleDisable()}
        />
      </div>
    </>
  )
}

export default StepFour
