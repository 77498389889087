export type IErrorObj = {
  name: string
  error: boolean
}

type IUseFormValidate = {
  fieldError: boolean
  name: string
  setErrorArray: React.Dispatch<React.SetStateAction<IErrorObj[]>>
  errorArray: IErrorObj[]
}

const UseValidateForm = ({
  fieldError,
  name,
  setErrorArray,
  errorArray
}: IUseFormValidate): boolean => {
  // Function to update error for a specific name
  const updateError = (error: boolean): void => {
    setErrorArray((prev: IErrorObj[]) => {
      // Check if there is an existing error entry for the name
      const existingIndex = prev.findIndex((item) => item.name === name)

      if (existingIndex !== -1) {
        // Update the existing error entry if error status changes
        if (prev[existingIndex].error !== error) {
          return prev.map((item, index) => {
            if (index === existingIndex) {
              return { ...item, error }
            }
            return item
          })
        }
        return prev // No changes needed
      } else {
        // Add a new error entry for the name if it doesn't exist
        return [...prev, { name, error }]
      }
    })
  }

  // Update error based on fieldError
  if (fieldError) {
    updateError(true) // Set error to true for the specific name
  } else {
    updateError(false) // Set error to false for the specific name
  }

  // Check if there is any error in the form
  const isFormValid = errorArray.some((item) => item.error)

  return isFormValid
}

export default UseValidateForm
