/* eslint-disable n/no-callback-literal */
import axios, { type AxiosResponse } from 'axios'
import i18n from 'i18next'
import urlDoc from './urlDoc'

type handleApiCallInterface<T> = {
  variant?: string
  urlType: string
  data?: T
  params?: any
  cb?: (returnData: AxiosResponse<T> | any, State: boolean) => void
  setLoading?: (state: boolean) => void
  urlParams?: string
  auth?: boolean
  signal?: AbortSignal
}

type CommonError = {
  message?: string
}

const convertToCommonError = (response: any): CommonError => {
  if (response?.data?.errorObject?.message !== undefined) {
    return {
      message: response.data.errorObject?.message
    }
  }

  if (response?.data?.grandidObject?.message?.status === 'failed') {
    console.error(
      'Check for error format2:',
      response.data.grandidObject?.message?.status
    )
    return {
      message: i18n.t('ErrorMsg.Grandid.LoginFailed')
    }
  }

  if (response.data?.count !== undefined && response.data?.count > 1) {
    console.error('Check for error format3:', response.data.count)

    return {
      message: i18n.t('ErrorMsg.Grandid.CountTwoError')
    }
  }

  if (
    response?.data !== undefined &&
    response.data?.status === 'error' &&
    response.data?.message !== undefined
  ) {
    console.error('Check for error format4:', response.data.message)
    return {
      message: response.data.message
    }
  }
  // Happens when auth token not valid
  if (response.response?.data?.message !== undefined) {
    console.error('Check for error format5:', response.message)
    return {
      message: response.response?.data?.message
    }
  }

  if (response.message !== undefined) {
    console.error('Check for error format6:', response.message)
    return {
      message: response.message
    }
  }
  return {
    message: ''
  }
}

const handleApiCall = async <T>({
  urlType,
  data,
  params,
  cb = (returnData: AxiosResponse<T>) => returnData,
  setLoading = (state: boolean) => state,
  urlParams = '',
  auth = false,
  signal
}: handleApiCallInterface<T>): Promise<void> => {
  const accountId = localStorage.getItem('account_id')
  const authToken = localStorage.getItem('auth_token')

  const url = `${urlDoc[urlType]?.url}${
    auth ? `&auth_token=${authToken}&account_id=${accountId}` : ''
  }${urlParams}`
  const method = urlDoc[urlType]?.type

  // console.log('url>>', url)

  async function handelCall(): Promise<void> {
    setLoading(true)
    try {
      const response = await axios({
        method,
        url,
        data,
        signal,
        params,
        headers: {
          'Content-Type':
            urlType === 'federatedLogin' ||
            urlType === 'getSession' ||
            urlType === 'save'
              ? 'application/x-www-form-urlencoded'
              : 'application/json'
        }
      })
      setLoading(false)

      if (response.data !== undefined) {
        // Check for error format
        const commonError = convertToCommonError(response).message
        // console.log('commonErrorcommonError1111', commonError)
        if (commonError !== undefined && commonError !== '') {
          // Adding message to response.data
          cb({ ...response.data, message: commonError }, false)
          return
        }
      }

      cb(response, true)
    } catch (error: any) {
      setLoading(false)

      // Format 01
      // error = {
      //   message: 'Hi fist error'
      // } as any

      // Format new 01
      // error = {
      //   ststus: 'error',
      //   message: [
      //     {
      //       error: {
      //         '+46dasdasdasd': 'not-found'
      //       }
      //     }
      //   ]
      // } as any

      const commonError = convertToCommonError(error).message
      // console.log('commonErrorcommonError2222', error.response?.data.popup)
      if (commonError !== undefined && commonError !== '') {
        cb(
          error?.response?.data !== undefined
            ? {
                ...error.response.data,
                message: error.response.data.message,
                popup: error.response.data.popup
              }
            : { message: commonError },
          false
        )
      } else {
        cb(error, false)
      }
    }
  }
  await handelCall()
}

export default handleApiCall
