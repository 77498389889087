import React from 'react'
// import { useLottie } from 'lottie-react'
// import Loading from '../../animations/loading.json'

type PropsTypes = {
  loading: boolean
  children: React.ReactNode
  loadingText?: string | React.ReactNode
  customClassName?: string
}

const TellitLoader = ({
  loading,
  children,
  loadingText,
  customClassName
}: PropsTypes): JSX.Element => {
  // const options = {
  //   animationData: Loading,
  //   loop: true
  // }

  // const { View } = useLottie(options)

  if (loading) {
    return (
      <div
        className={`${customClassName} flex flex-col justify-center items-center h-[20rem]`}
      >
        {/* <div>{View}</div> */}
        <span className="loading loading-spinner loading-lg bg-secondary" />{' '}
        <br />
        {loadingText}
      </div>
    )
  }
  return <>{children}</>
}

export default TellitLoader
