/* eslint-disable prettier/prettier */
import { useEffect } from 'react'
import { type IErrorObj } from './useFormValidate'

type IUserErrorArray = {
    errorArray: IErrorObj[]
    setErrorArray: (value: any) => void
    setIsError: (value: boolean) => void
    step: number
}

const useErrorArray = ({
    errorArray,
    setErrorArray,
    setIsError,
    step
}: IUserErrorArray): null => {
    useEffect(() => {
        if (errorArray?.length > 0) {
            localStorage.setItem(`errorArray${step}`, JSON.stringify(errorArray))
        } else {
            const storedErrorArray = localStorage.getItem(`errorArray${step}`)
            if (storedErrorArray !== null) {
                setErrorArray(JSON.parse(storedErrorArray))

                const isFormValid = errorArray.some((item) => item.error)
                if (isFormValid) {
                    setIsError(true)
                } else {
                    setIsError(false)
                }
            }
        }
    }, [errorArray])
    return null
}

export default useErrorArray
