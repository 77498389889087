import React from 'react'

type TellitButtonProps = {
  label: string | undefined
  type?: 'button' | 'submit' | 'reset'
  customClass?: string
  onClick?: () => void
  icon?: JSX.Element
  href?: string
  disabled?: boolean
}

const TellitButton = ({
  label,
  customClass,
  onClick,
  type = 'button',
  icon,
  href,
  disabled = false
}: TellitButtonProps): JSX.Element => {
  return (
    <a
      href={disabled ? undefined : href}
      className={`${customClass} group w-auto min-w-[8rem] min-h-[48px] uppercase text-smb text-primary font-semibold rounded-[31px] px-4 py-sp4 antialiased shadow-sm flex items-center justify-center gap-4 ${
        disabled
          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
          : 'bg-secondary cursor-pointer'
      } ${icon != null ? 'justify-between' : 'justify-center'}`}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
          e.stopPropagation()
        } else {
          if (onClick !== null && onClick !== undefined) onClick()
        }
      }}
      type={type}
    >
      {icon != null && <div>{icon}</div>}
      <span className={disabled ? 'text-gray-500' : 'text-primary'}>
        {label}
      </span>
    </a>
  )
}

export default TellitButton
