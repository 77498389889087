import { UserSetup } from 'main'

function App(): JSX.Element {
  return (
    <div>
      <UserSetup />
    </div>
  )
}

export default App
