import React, { useState, useRef, useEffect } from 'react'
import TellitInput from 'components/form/TellitInput'
import {
  BackBtn,
  CardBtn,
  NextBtn,
  CardBtnWrapper,
  Header
} from './subComponents'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import {
  NavigateForward,
  NavigateBackward,
  UpdateUserValue,
  SetCardData,
  SetLoading
} from 'store/userSetup/action'
import { useTranslation } from 'react-i18next'
import handleApiCall from 'api/handleApiCall'
import { type AreaCodeNumberType } from 'store/userSetup/types'
import TellitCheckbox from 'components/form/TellitCheckbox'
import { HiOutlineArrowRightCircle, HiOutlinePlusCircle } from 'react-icons/hi2'
import { TfiClose } from 'react-icons/tfi'
import { handleToastMessage } from 'utils/handleToastMesasge'
import UseValidateForm, { type IErrorObj } from 'utils/useFormValidate'
import { getErrorInField } from 'utils/commonFunctions'
import useErrorArray from 'utils/useErrorArray'

type NumberInput = {
  focus: () => void
}

const StepThree = ({ stepState, setStepState }: any): JSX.Element => {
  const [areaCodeLoading, setAreaCodeLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])
  // terms and conditions state
  const [viewTerms, setViewTerms] = useState<boolean>(false)
  // accepted
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const numberRef = useRef<NumberInput>(null)
  const telephoneNumberRef = useRef<any>(null)
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )
  const selectValues = useSelector(
    (state: RootState) => state.userSetup.cardData
  )

  const handleOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ): JSX.Element | null => {
    const { name, value } = e.target
    // update redux store
    dispatch(UpdateUserValue({ name, value }))

    // fetch numbers
    if (name === 'selectedNewNumber') {
      dispatch(SetLoading({ name: 'loading', value: true }))
      void handleApiCall({
        urlType: 'getNumbers',
        urlParams: value,
        setLoading: setAreaCodeLoading,
        cb: (res: any, state) => {
          dispatch(SetLoading({ name: 'loading', value: false }))
          setStepState(1)
          if (state) {
            const transformedArray = (res?.data as AreaCodeNumberType)?.map(
              (item: { number: string }) => {
                return { value: item.number, name: item.number }
              }
            )
            const value: any = transformedArray
            dispatch(SetCardData({ name: 'numbers', value }))
          } else {
            if (res.message !== undefined && res.message !== '') {
              handleToastMessage(res.message)
            }
            console.log('error')
          }
        }
      })
    }
    return null
  }

  const cardBtns = [
    {
      btnTitle: t('StepThree.Card.OwnNumber.Text'),
      icon: (
        <HiOutlineArrowRightCircle
          className="text-3xl font-bold"
          strokeWidth="2.5"
        />
      ),
      disabled: true
    },
    {
      btnTitle: t('StepThree.Card.NewNumber.Text'),
      icon: <HiOutlinePlusCircle className="text-3xl" strokeWidth="2.5" />,
      disabled: false
    }
  ]

  const formsOne = [
    {
      label: t('StepThree.Form.PhoneNumberTwo.Label'),
      id: 'telephoneNumber',
      name: 'telephoneNumber',
      value: formValues.telephoneNumber,
      type: 'text',
      placeholder: '',
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange?.(e)
      },
      enableErrorMsg: true,
      validationType: 'landline'
    },
    {
      label: t('StepThree.Form.Carrier.Label'),
      id: 'carrier',
      name: 'carrier',
      value: formValues.carrier,
      type: 'text',
      placeholder: '',
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange?.(e)
      },
      enableErrorMsg: true,
      validationType: ''
    }
  ]

  const formTwo = [
    {
      label: t('StepThree.Form.SelectedNumber.Label'),
      id: 'selectedNewNumber',
      name: 'selectedNewNumber',
      inputType: 'select',
      options: selectValues.areaCodes,
      value: formValues.selectedNewNumber,
      type: 'text',
      placeholder: t('StepThree.Form.SelectedNumber.Placeholder'),
      required: true,
      onSelect: (ex: any) => {
        const e: any = {
          target: { name: 'selectedNewNumber', value: ex.value }
        }
        handleOnChange?.(e)
      },
      disabled: areaCodeLoading,
      validationType: 'newNumber'
    }
  ]

  const terms = [
    t('StepThree.Terms.One'),
    t('StepThree.Terms.Two'),
    t('StepThree.Terms.Three'),
    t('StepThree.Terms.Four'),
    t('StepThree.Terms.Five')
  ]

  const handleBtnDisable = (): boolean => {
    if (stepState === 0) {
      return formValues.numberType === -1
    }
    if (stepState === 1) {
      if (formValues.numberType === 0) {
        return (
          formValues.telephoneNumber === '' ||
          formValues.carrier === '' ||
          isError ||
          !isTermsAccepted
        )
      }
      return (
        formValues.selectedNewNumber === '' ||
        formValues.telephoneNumber === '' ||
        isError
      )
    }
    return false
  }

  const navigateForward = (): void => {
    if (stepState === 0) {
      setStepState(1)
    } else {
      dispatch(NavigateForward())
    }
  }

  useEffect(() => {
    if (numberRef?.current !== null && formValues.selectedNewNumber === '') {
      numberRef?.current.focus()
    }
  }, [stepState])

  useErrorArray({
    errorArray,
    setErrorArray,
    setIsError,
    step: 3
  })

  return (
    <>
      {viewTerms ? (
        <div className="mt-[-2rem] md:mx-sp9 shadow-lg shadow-black/20 rounded-lg p-2 px-8 bg-white/60">
          <div className="flex justify-between h-[2rem] mt-4">
            <div className="text-base-20 font-medium text-primary mt-auto">
              {t('StepThree.State.One.Terms.Header')}
            </div>
            <div
              className="text-black cursor-pointer"
              onClick={() => {
                setViewTerms(false)
              }}
            >
              <TfiClose fontSize="14px" />
            </div>
          </div>
          <div className="my-4 flex flex-col gap-3 2xl:gap-4">
            {terms.map((term, idx) => (
              <div
                key={`term_${idx}`}
                className="text-black/80 text-[0.9rem] md:text-base-20 font-light tracking-wide leading-5 antialiased"
              >
                {idx + 1}. {term}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          {stepState === 0 ? (
            <div className="mb-sp190">
              <Header className="md:px-10 font-medium 2xl:mt-[-6px]">
                {/* {t('StepOne.CreditsafeError.Label1')} */}
                {t('StepThree.State.Zero.Header')}
                <br></br>
                <br></br>
              </Header>
              <CardBtnWrapper customClass="mb-4 !justify-center step-3-card-btn-parent 2xl:mt-[15px]">
                {cardBtns.map((btn, idx) => (
                  <CardBtn
                    key={`cardbtn_${idx}`}
                    label={btn.btnTitle}
                    icon={btn.icon}
                    // disabled={btn.disabled}
                    handleClick={() => {
                      const name = 'numberType'
                      const value = idx
                      dispatch(UpdateUserValue({ name, value }))

                      navigateForward()
                    }}
                    customClass={`${
                      formValues.numberType === idx
                        ? 'text-white !bg-neutral'
                        : 'text-primary/90 bg-white'
                    } max-w-[190px] h-sp100 !gap-[0.4rem]`}
                  />
                ))}
              </CardBtnWrapper>
            </div>
          ) : stepState === 1 ? (
            <div className="mb-8">
              {formValues.numberType === 0 ? (
                <>
                  <div className="flex flex-col gap-8">
                    {formsOne.map((formItem, idx) => (
                      <div
                        key={idx}
                        className="flex flex-col justify-center gap-4"
                      >
                        <TellitInput
                          key={`${idx}_formItem`}
                          label={formItem.label}
                          id={formItem.id}
                          name={formItem.name}
                          value={formItem.value}
                          type={formItem.type}
                          placeholder={formItem.placeholder}
                          onChange={formItem.onChange}
                          enableErrorMsg={formItem.enableErrorMsg}
                          validationType={formItem.validationType}
                          setError={(val) => {
                            const isFormError = UseValidateForm({
                              fieldError: val,
                              errorArray,
                              name: formItem.name,
                              setErrorArray
                            })
                            setIsError(isFormError)
                          }}
                          required={formItem.required}
                          triggerValidationOnMount={getErrorInField(
                            formItem.name,
                            errorArray
                          )}
                        />
                        {idx === 0 && (
                          <span className="mx-auto text-black/80 text-xs font-light text-center">
                            {t('StepThree.State.One.Input.Subtitle')}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="md:mx-sp60 2xl:mx-sp80 text-sm font-light flex gap-1 items-center bg-white/50 rounded-xl p-1 px-3 text-primary mt-8 mb-6 2xl:mb-[75px] step-three-check py-2 ">
                    <TellitCheckbox
                      checked={isTermsAccepted}
                      name="checked"
                      customClassName="!rounded checkbox !w-[1rem] !h-[1rem] checkbox-secondary "
                      handleOnChange={(e) => {
                        setIsTermsAccepted(e.target.checked)
                      }}
                    />
                    <span className="text-[0.7rem] md:text-[0.8rem] text-black">
                      {t('StepThree.State.One.Check.Description')}
                      <b
                        onClick={() => {
                          setViewTerms(true)
                        }}
                        className="cursor-pointer text-primary/90 ml-1 font-semibold"
                      >
                        {t('StepThree.State.One.Check.Terms')}
                      </b>
                    </span>
                  </div>
                </>
              ) : (
                <div className="mb-[5rem]">
                  <Header className="md:px-10 !font-light">
                    {t('StepThree.State.One.Check.NumberOne')}
                    <br></br>
                    <br></br>
                  </Header>
                  <div className="flex flex-col gap-8">
                    {formTwo
                      // .filter((item, index) => {
                      //   if (formValues.numberType === index) {
                      //     return item
                      //   } else return null
                      // })
                      .map((formItem, idx) => (
                        <TellitInput
                          key={`${idx}_formItem`}
                          label={formItem.label}
                          id={formItem.id}
                          name={formItem.name}
                          inputType={formItem.inputType}
                          options={formItem.options}
                          value={formItem.value}
                          type={formItem.type}
                          placeholder={formItem.placeholder}
                          // onChange={formItem.onChange}
                          onSelect={formItem.onSelect}
                          disabled={formItem.disabled}
                          // enableErrorMsg={formItem.enableErrorMsg}
                          validationType={formItem.validationType}
                          required={formItem.required}
                          setError={(val) => {
                            const isFormError = UseValidateForm({
                              fieldError: val,
                              name: formItem.name,
                              errorArray,
                              setErrorArray
                            })
                            setIsError(isFormError)
                          }}
                          openMenuOnFocus={true}
                          inputRef={numberRef}
                        />
                      ))}

                    {formValues.selectedNewNumber !== '' && (
                      <TellitInput
                        label={t('StepThree.Form.PhoneNumber.Label')}
                        name="telephoneNumber"
                        placeholder={t(
                          'StepThree.Form.PhoneNumber.Placeholder'
                        )}
                        inputType="select"
                        options={selectValues.numbers}
                        value={formValues.telephoneNumber}
                        onSelect={(ex: any): any => {
                          const e: any = {
                            target: {
                              name: 'telephoneNumber',
                              value: ex.value
                            }
                          }
                          handleOnChange?.(e)
                          if (telephoneNumberRef?.current !== null) {
                            telephoneNumberRef?.current.blur()
                          }
                        }}
                        inputRef={telephoneNumberRef}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <div
            className={`self-center mt-8 flex flex-col ${
              stepState !== 0 && 'md:flex-row'
            } flex-wrap justify-between 2xl:justify-center 2xl:gap-[50px] items-center w-full md:px-sp150 lg:px-sp50 2xl:px-sp180 footer-nav"`}
          >
            <BackBtn
              handleClick={() => {
                if (stepState === 0) {
                  dispatch(NavigateBackward())
                } else {
                  setStepState(0)
                }
              }}
              customClass="2xl:pl-0"
            />
            {stepState !== 0 && (
              <NextBtn
                handleClick={() => {
                  navigateForward()
                }}
                disabled={handleBtnDisable()}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default StepThree
