import React from 'react'

type TellitProgressProps = {
  progressLength?: number
  completedSteps?: number
}

const TellitProgress = ({
  progressLength = 6,
  completedSteps = 0
}: TellitProgressProps): JSX.Element => {
  const getProgressLength = Array.from(
    { length: progressLength },
    (_, index) => index + 1
  )

  const progress = getProgressLength.map((_, idx) => (
    <div
      className={`h-[6px] ${
        completedSteps <= idx ? 'bg-secondaryLight/30' : 'bg-neutral'
      } w-fill max-w-[101px] rounded-[30px]`}
      key={`progress_step_${idx}`}
    />
  ))
  return (
    <div className="flex gap-4 items-center justify-between max-w-5xl 2xl:pt-[6px]">
      {progress}
    </div>
  )
}

export default TellitProgress
