import * as types from './constant'
import { type ActionType, type UpdateUserValuePropTypes } from './types'

export const NavigateForward = (payload?: any): ActionType => ({
  type: types.NAVIGATE_FORWARD,
  payload
})
export const NavigateBackward = (payload?: any): ActionType => ({
  type: types.NAVIGATE_BACKWARD,
  payload
})

export const UpdateUserValue = (
  payload: UpdateUserValuePropTypes
): ActionType => ({
  type: types.UPDATE_USER_SETUP_VALUE,
  payload
})

export const SetCardData = (payload: UpdateUserValuePropTypes): ActionType => ({
  type: types.SET_CARD_DATA,
  payload
})

export const SetLoading = (payload: UpdateUserValuePropTypes): ActionType => ({
  type: types.SET_STEP_LOADING,
  payload
})
