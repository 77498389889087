import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { sv } from 'translation'

const resources = {
  // swedish
  sv: {
    translation: sv
  }
}

void i18n.use(initReactI18next).init({
  resources,
  lng: 'sv',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
