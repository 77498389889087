import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLottie } from 'lottie-react'

import { NextBtn } from './subComponents'
import thumbJson from '../../../animations/thumb.json'
import GooglePlayStore from '../../../assets/images/GooglePlayStore.png'
import AppStoreDownload from '../../../assets/images/AppStoreDownload.png'
const StepSix = (): JSX.Element => {
  const { t } = useTranslation()
  const options = {
    animationData: thumbJson,
    loop: true
  }

  const { View } = useLottie(options)

  return (
    <>
      <div>
        <div className="w-52 h-52 m-auto flex flex-row gap-8">{View}</div>

        <div className="self-center w-auto mt-8 flex flex-col xl:flex-row flex-wrap justify-center items-center">
          <NextBtn
            label={t('StepFinish.Button.GoToAdmin')}
            handleClick={() => {
              window.open('https://minasidorfree.tellit.se/', '_self')
            }}
          />
        </div>
        <div className="w-full flex justify-center pt-4 space-x-4">
          <a
            href="https://apps.apple.com/se/app/tellit-unity/id6479322916"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 xl:mt-0"
          >
            <img
              src={AppStoreDownload}
              alt="Download on the App Store"
              className="h-12 object-contain"
            />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.tellitapp"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 xl:mt-0"
          >
            <img
              src={GooglePlayStore}
              alt="Get it on Google Play"
              className="h-12 object-contain"
            />
          </a>
        </div>
      </div>
    </>
  )
}

export default StepSix
