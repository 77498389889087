import TellitButton from 'components/common/TellitButton'
import {
  type ButtonType,
  type CardBtnWrapperType,
  type HeaderType
} from 'store/userSetup/types'
import i18next from 'i18next'
import {
  FaRegHandPointLeft,
  FaRegHandPointRight,
  FaRegHandPointUp
} from 'react-icons/fa'

const NextBtn = ({
  handleClick,
  customClass = '',
  disabled = false,
  label = i18next.t('Generic.Button.Next'),
  type
}: ButtonType): JSX.Element => (
  <TellitButton
    label={label}
    icon={type === 'up' ? <FaRegHandPointUp /> : <FaRegHandPointRight />}
    disabled={disabled}
    onClick={() => {
      if (!disabled) {
        handleClick()
      }
    }}
    customClass={`${customClass} h-[48px] bg-gray-300 disabled:bg-black disabled:text-black/50`}
  />
)

const BackBtn = ({
  handleClick,
  disabled,
  customClass
}: ButtonType): JSX.Element => (
  <TellitButton
    label={i18next.t('Generic.Button.Back')}
    icon={<FaRegHandPointLeft />}
    disabled={disabled}
    onClick={() => {
      handleClick()
    }}
    customClass={`${customClass} back-btn !shadow-none bg-transparent flex-row-reverse disabled:bg-transparent disabled:text-black/50 disabled:cursor-not-allowed cursor-pointer`}
  />
)

const CardBtn = ({
  handleClick,
  label,
  icon,
  customClass,
  disabled
}: ButtonType): JSX.Element => (
  <TellitButton
    label={label}
    icon={icon}
    customClass={`h-20 flex-col-reverse gap-1 capitalize flex-1 !justify-center shadow-sm items-center bg-neutral !text-md !font-medium ${customClass} `}
    onClick={() => {
      handleClick()
    }}
    disabled={disabled}
  />
)

const CardBtnWrapper = ({
  children,
  customClass
}: CardBtnWrapperType): JSX.Element => (
  <div
    className={`${customClass} flex justify-between items-center gap-6 mx-[-2rem] `}
  >
    {children}
  </div>
)

const Header = ({ children, className }: HeaderType): JSX.Element => (
  <p
    className={`font-base-20 font-normal text-primary word-wrap subpixel-antialiased tracking-wide break-words text-center ${className}`}
  >
    {children}
  </p>
)

export { Header, NextBtn, BackBtn, CardBtn, CardBtnWrapper }
