import * as types from './constant'
import { type UserSetupStateTypes, type ActionType } from './types'

const initialState: UserSetupStateTypes = {
  step: 1,
  userValues: {
    orgNumber: '',
    companyName: '',
    personNumber: '',
    name: '',
    mobile: '',
    email: '',
    address: '',
    zip: '',
    city: '',
    areaCode: '',
    numberType: -1,
    existingNumber: '',
    selectedNewNumber: '',
    telephoneNumber: '',
    newNumber: '', // we don't use this
    carrier: '',
    users: [],
    openTime: -1,
    callType: -1,
    confirm1: false,
    confirm2: false,
    weekendsstart: '',
    weekendsend: '',
    lunchstart: '',
    lunchend: '',
    creditsafeRating: ''
  },
  cardData: {
    loading: false,
    areaCodes: [],
    numbers: []
  }
}

export default (
  state = initialState,
  action: ActionType
): typeof initialState => {
  switch (action.type) {
    case types.SET_STEP_LOADING:
      return {
        ...state,
        cardData: {
          ...state.cardData,
          loading: Boolean(action.payload?.value ?? false)
        }
      }
    case types.SET_CARD_DATA:
      if (action.payload != null) {
        return {
          ...state,
          cardData: {
            ...state.cardData,
            [action.payload.name]: action.payload.value
          }
        }
      }
      return state
    case types.NAVIGATE_FORWARD:
      return {
        ...state,
        step: state.step + 1
      }
    case types.NAVIGATE_BACKWARD:
      return {
        ...state,
        step: state.step - 1
      }
    case types.UPDATE_USER_SETUP_VALUE:
      if (action.payload != null) {
        return {
          ...state,
          userValues: {
            ...state.userValues,
            [action.payload.name]: action.payload.value
          }
        }
      }
      return state
    default:
      return state
  }
}
