import React from 'react'

type NumberBadgeProps = {
  count: number
}

const NumberBadge = ({ count }: NumberBadgeProps): JSX.Element => {
  return (
    <div className="flex items-center justify-center bg-neutral h-8 w-8 rounded-full aspect-square text-[0.9rem] md:text-[1rem] text-white mr-2 shadow-2xl">
      {count}
    </div>
  )
}

export default NumberBadge
