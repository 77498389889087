import React from 'react'
import Tellit_Free_Villkor from '../../assets/pdf/Tellit_Free_Villkor.pdf'

type PropsTypes = {
  vilkorText?: string
  label?: string
  checked: boolean
  customClassName?: string
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  id?: string
}

const TellitCheckbox = ({
  vilkorText,
  label,
  checked,
  customClassName,
  handleOnChange,
  name,
  id
}: PropsTypes): JSX.Element => {
  return (
    <div className="flex gap-4 items-start">
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        className={`checkbox-custom rounded-full checked:bg-neutral border-1 border-neutral mt-2 ${customClassName}`}
        onChange={(e) => {
          handleOnChange(e)
        }}
      />

      {vilkorText !== undefined ? (
        <div className="flex flex-col md:flex-row items-start md:items-center w-full md:w-auto">
          <label className="text-[0.9rem] !leading-7 md:text-base-20 text-primary font-light">
            {label}
          </label>
          <a
            className="text-[0.9rem] !leading-7 md:text-base-20 text-primary font-light underline md:ml-2 md:w-auto w-full"
            href={Tellit_Free_Villkor}
            download
            rel="noopener noreferrer"
          >
            {vilkorText}
          </a>
        </div>
      ) : (
        <label className="text-[0.9rem] !leading-7 md:text-base-20 text-primary font-light">
          {label}
        </label>
      )}
    </div>
  )
}

export default TellitCheckbox
