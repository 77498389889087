type ICheckPersonNumber = {
  personNumber?: string
  comparingNumber: string
}

const CheckPersonNumber = ({
  personNumber,
  comparingNumber
}: ICheckPersonNumber): boolean => {
  // Remove non-numeric characters
  const cleanUpNumber = (num: string): string => {
    return num.replace(/[^\d]/g, '')
  }
  // Clean up input personal numbers
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const cleanPersonNumber = personNumber ? cleanUpNumber(personNumber) : ''
  const cleanComparingNumber = cleanUpNumber(comparingNumber)
  // console.log(cleanPersonNumber, cleanComparingNumber, 'cleaned numbers')
  // Check if the lengths match
  if (cleanPersonNumber.length !== cleanComparingNumber.length) {
    return false
  }
  // Check if the numbers match
  return Number(cleanPersonNumber) === Number(cleanComparingNumber)
}

export default CheckPersonNumber
