import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

type TellitInputProps = {
  type?: string
  inputType?: string
  label?: string
  id?: string
  name?: string
  value?: string | number
  customClass?: string
  alignCenter?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelect?: any // later need to fix
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void
  options?: Array<{ name: string; value: string | number }>
  placeholder?: string
  errorMsg?: string
  enableErrorMsg?: boolean
  enableTypeValidation?: boolean
  validationType?: string
  formValues?: any
  disabled?: boolean
  setError?: (value: boolean) => void
  required?: boolean
  menuIsOpen?: boolean
  openMenuOnFocus?: boolean
  inputRef?: any
  errorMsgClass?: string
  triggerValidationOnMount?: boolean
}

const TellitInput = ({
  inputType = 'input',
  type = 'text',
  label,
  id,
  name,
  value,
  customClass,
  onChange = () => {},
  onBlur = () => {},
  alignCenter = false,
  options = [],
  onSelect,
  placeholder,
  errorMsg = '',
  enableErrorMsg = false,
  enableTypeValidation = true,
  validationType = '',
  formValues,
  disabled = false,
  menuIsOpen,
  openMenuOnFocus,
  setError,
  required,
  inputRef,
  errorMsgClass,
  triggerValidationOnMount
}: TellitInputProps): JSX.Element => {
  const [builtInErrorMsg, setBuiltInErrorMsg] = useState('')
  const { t } = useTranslation()
  const modOptions: any = options?.map((option) => ({
    value: option.value,
    label: option.name
  }))

  const textInputRef = useRef<HTMLInputElement>(null)

  const validateInput = (value: string): null => {
    // const { value } = e.target

    if (required === true && value.length === 0) {
      setBuiltInErrorMsg(`${label} ${t('ErrorMsg.Form.Required')}`)
      setError?.(true)
    } else {
      setBuiltInErrorMsg('')
      setError?.(false)
    }

    if (value.length > 255) {
      setBuiltInErrorMsg(t('ErrorMsg.Form.MaximumLength'))
    } else {
      setBuiltInErrorMsg('')
    }

    if (validationType === 'personalNumber') {
      if ((value.length > 0 && value.length < 10) || value.length > 12) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidPersonalNumber'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'organizationNumber') {
      if ((value.length > 0 && value.length < 10) || value.length > 12) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidOrganizationNumber'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (type === 'email') {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      if (!emailRegex.test(value) && value.length > 1) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidEmail'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'email') {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      if (
        (value === formValues.email && formValues.email !== '') ||
        value === ''
      ) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.EmailExists'))
        setError?.(true)
      } else if (!emailRegex.test(value)) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidEmail'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'mobile') {
      // Regular expression for valid Swedish mobile phone numbers
      const swedishMobileRegex = /^07[0-9]{8}$/

      if (!swedishMobileRegex.test(value) && value.length > 1) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidMobile'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'landline') {
      // Regular expression for valid Swedish landline phone numbers
      const swedishLandlineRegex = /^(?!0[7])0[1-9]\d{6,8}$/

      if (!swedishLandlineRegex.test(value)) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.InvalidLandPhoneNumber'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    if (validationType === 'newNumber' && required === true) {
      if (value.length === 0) {
        setBuiltInErrorMsg(t('ErrorMsg.Form.Required'))
        setError?.(true)
      } else {
        setBuiltInErrorMsg('')
        setError?.(false)
      }
    }

    return null
  }

  const getValue = (): any => {
    return modOptions.find(
      (option: { value: string }) => option.value === value
    )
  }

  // Run validation on mount
  useEffect(() => {
    if (triggerValidationOnMount as boolean) {
      if (onBlur === null || onBlur === undefined) {
        validateInput(value as string)
      }
      textInputRef.current?.focus()
      setTimeout(() => {
        textInputRef.current?.blur()
      }, 10)
      setTimeout(() => {
        textInputRef.current?.focus()
      }, 11)
    }
  }, [triggerValidationOnMount])

  return (
    <div
      className={`flex items-center ${
        alignCenter ? 'justify-center' : 'justify-start'
      } flex-col`}
    >
      <label
        htmlFor={id}
        className="block font-semibold tracking-wide uppercase text-base text-primary input-label pb-[20px] leading-none"
      >
        {label}
      </label>
      {inputType === 'input' && (
        <input
          ref={textInputRef}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={(e) => {
            if (
              enableTypeValidation &&
              onBlur === null &&
              onBlur === undefined
            ) {
              validateInput(e.target.value)
            }
            onChange(e)
          }}
          disabled={disabled}
          // style={{
          //   border: '1px solid #D497F580'
          // }
          onBlur={(e) => {
            if (enableTypeValidation) {
              validateInput(e.target.value)
            }
            onBlur !== null && onBlur !== undefined && onBlur(e)
          }}
          placeholder={placeholder}
          className={`${customClass} text-center focus-visible border-secondaryLight border-[1px] input-md input-width h-[35px] md:h-[45px] input-white bg-white text-primary font-normal text-md  !outline-offset-0 !outline-1 rounded-[36px]`}
        />
      )}

      {inputType === 'select' && (
        <Select
          id={id}
          ref={inputRef}
          onChange={onSelect}
          isDisabled={disabled}
          value={getValue()}
          name={name}
          options={modOptions}
          placeholder={placeholder}
          menuIsOpen={menuIsOpen}
          openMenuOnFocus={openMenuOnFocus}
          className="tellit-select h-[35px] md:h-[45px] input-width"
          classNamePrefix="t-select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (props) => (
              <div className="pr-6">
                {props.isFocused ? (
                  <MdExpandLess className="text-[1.5rem]" />
                ) : (
                  <MdExpandMore className="text-[1.5rem]" />
                )}
              </div>
            )
          }}
        />
      )}
      <div>
        {enableErrorMsg && (
          <p
            className={`text-center text-error text-[0.8rem] md:text-base-20 font-normal mt-sp3 mb-[-1rem] ${errorMsgClass}`}
          >
            {errorMsg.length > 0 ? errorMsg : builtInErrorMsg}
          </p>
        )}
      </div>
    </div>
  )
}

export default TellitInput
