import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'

import TellitCard from 'components/common/TellitCard'
import TellitProgress from 'components/common/TellitProgress'
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
  StepFinish
} from './steps'
import TellitLoader from 'components/common/TellitLoader'

const UserSetup = (): JSX.Element => {
  const step = useSelector((state: RootState) => state.userSetup.step)
  const { loading } = useSelector(
    (state: RootState) => state.userSetup.cardData
  )
  const [stepState, setStepState] = useState(0)
  const { t } = useTranslation()
  const GetStepForm = (): JSX.Element | null => {
    const steps = [
      <StepOne />,
      <StepTwo />,
      <StepThree setStepState={setStepState} stepState={stepState} />,
      <StepFour />,
      <StepFive />,
      <StepSix />,
      <StepFinish />
    ]
    const selectedStep = steps[step - 1]
    return selectedStep
  }

  return (
    <div className="bg-accent h-auto min-h-screen pb-6 md:pb-0 flex flex-col lg:flex-row gap-4 px-6 pt-8 lg:pt-0 lg:px-0">
      <div className="flex flex-col items-start justify-start w-auto lg:w-1/2 lg:pt-[12rem] lg:px-[4rem] 2xl:px-sp150 top-p 2xl:mt-[-10px] mb-8 lg:mb-auto header-container">
        <h2 className="text-[1.8rem] md:text-[2.5rem] 2xl:text-2xl mt-12 lg:mt-auto  text-primary text-center lg:text-left lg:text-[4rem] font-semibold leading-none mx-auto lg:mx-0">
          {t('UserStep.Title')}
        </h2>
        <h4 className="2xl:mt-[-12px] text-[1.2rem] lg:text-[1.7rem] 2xl:text-xl mt-3 font-semibold text-center lg:text-left mx-auto left-subtitle lg:mt-0 lg:mx-0 text-primary">
          {t('UserStep.Subtitle')}
        </h4>
      </div>

      <div className="flex items-start w-auto lg:w-1/2 lg:pt-[12rem] lg:pr-[4rem]  2xl:pr-[12rem] top-p card-side-wrapper">
        <TellitCard customClass="w-fill lg:min-h-auto max-w-[50rem] mb-20">
          <h3 className="text-[1.6rem] lg:text-[1.75rem] 2xl:text-[30px] 2xl:leading-[50px] text-center font-semibold leading-none mb-[20px] text-primary 2xl:mt-[-7px]">
            {t('UserStep.Card.Title')}
          </h3>
          <TellitProgress completedSteps={step} />
          <div className="mt-sp55 2xl:pt-[9px] m-auto flex flex-col gap-4 md:gap-10 2xl:gap-[40px] step-wrapper">
            <TellitLoader loading={loading}>
              <GetStepForm />
            </TellitLoader>
          </div>
        </TellitCard>
      </div>
    </div>
  )
}

export default UserSetup
