import {
  BackBtn,
  CardBtn,
  CardBtnWrapper,
  Header,
  NextBtn
} from './subComponents'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import {
  NavigateBackward,
  NavigateForward,
  UpdateUserValue
} from 'store/userSetup/action'
import { useTranslation } from 'react-i18next'
import { FiUser, FiUsers } from 'react-icons/fi'
import { IoInfiniteOutline } from 'react-icons/io5'

type handleCardClickType = (type: string, val: number) => void

const StepFive = (props: any): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )
  const handleCardClick: handleCardClickType = (type, value) => {
    if (type === 'openTime') {
      const name = 'openTime'
      dispatch(UpdateUserValue({ name, value }))
    }
    if (type === 'callType') {
      const name = 'callType'
      dispatch(UpdateUserValue({ name, value }))
    }
  }

  const cardBtns = [
    {
      btnTitle: t('StepFive.Card.Button.Always'),
      icon: <IoInfiniteOutline className="text-md-icon-base" />
    }
    // {
    //   btnTitle: t('StepFive.Card.Button.TimeRange'),
    //   icon: <FiClock className="text-md-icon-base" />
    // }
  ]

  const cardBtnsTwo = [
    {
      btnTitle: t('StepFive.Card.Button.Once'),
      icon: <FiUsers className="text-md-icon-base" />
    },
    {
      btnTitle: t('StepFive.Card.Button.Round'),
      icon: <FiUser className="text-md-icon-base" />
    }
  ]
  return (
    <div className="mx-[-2rem] flex flex-col gap-7 step-four-wrapper">
      <Header className="!font-semibold !uppercase">
        {t('StepFive.Card.Header.One')}
      </Header>
      {/* <p>{dispatch.arguments}</p> */}

      <CardBtnWrapper customClass="flex-col step-5-card-parent sm:flex-row lg:flex-row  xl:flex-row !mx-auto 2xl:!mx-[-2rem]">
        {cardBtns.map((btn, idx) => (
          <CardBtn
            key={`cardBtn_1_${idx}`}
            label={btn.btnTitle}
            icon={btn.icon}
            customClass={`text-white !bg-neutral 2xl:max-w-[276px]  h-sp100 !py-4 !px-2 duration-200 transition-all delay-50 step-5-btn`}
            handleClick={() => {
              handleCardClick('openTime', idx)
            }}
          />
        ))}
      </CardBtnWrapper>
      <p className="font-medium word-wrap subpixel-antialiased tracking-wide break-words text-center text-smb mb-6  text-primary">
        {t('StepFive.Card.Description.One')}
      </p>
      <Header className="!font-semibold !uppercase">
        {t('StepFive.Card.Header.Two')}
      </Header>

      <CardBtnWrapper customClass="flex-col step-5-card-parent sm:flex-row lg:flex-row xl:flex-row !mx-auto 2xl:!mx-[-2rem]">
        {cardBtnsTwo.map((btn, idx) => (
          <CardBtn
            key={`cardBtn_2_${idx}`}
            label={btn.btnTitle}
            icon={btn.icon}
            customClass={`${
              formValues.callType === idx
                ? 'text-white !bg-neutral'
                : 'text-primary bg-white'
            }  2xl:max-w-[276px]  h-sp100 !py-4 !px-2 duration-200 transition-all delay-50 step-5-btn`}
            handleClick={() => {
              handleCardClick('callType', idx)
              handleCardClick('openTime', 0)
            }}
          />
        ))}
      </CardBtnWrapper>
      <div className="self-center lg:justify-center lg:gap-[40px] 2xl:gap-[60px]  mt-8 flex flex-col md:flex-row flex-wrap justify-between items-center w-full 2xl:px-sp120 step-5-footer">
        <BackBtn
          handleClick={() => {
            dispatch(NavigateBackward())
          }}
        />
        <NextBtn
          handleClick={() => {
            dispatch(NavigateForward())
          }}
          disabled={formValues.openTime === -1 || formValues.callType === -1}
          label={t('StepFive.Navigation.Button.Next')}
        />
      </div>
    </div>
  )
}

export default StepFive
