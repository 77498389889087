import { type IErrorObj } from './useFormValidate'

export const notNullUndefinedCheck = (data: any): boolean => {
  if (data !== undefined && data !== null && data !== '') {
    return true
  } else {
    return false
  }
}

export const getErrorInField = (
  name: string,
  errorArray: IErrorObj[]
): boolean => {
  const error = errorArray?.find((item) => item.name === name)
  return error?.error as boolean
}
