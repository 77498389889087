import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import type { RootState } from 'store'
import {
  NavigateBackward,
  NavigateForward,
  UpdateUserValue,
  SetCardData,
  SetLoading
} from 'store/userSetup/action'
import handleApiCall from 'api/handleApiCall'

import { handleToastMessage } from 'utils/handleToastMesasge'

import TellitInput from 'components/form/TellitInput'

import { BackBtn, Header, NextBtn } from './subComponents'
import TellitLoader from 'components/common/TellitLoader'
import useValidateForm, { type IErrorObj } from 'utils/useFormValidate'
import useErrorArray from 'utils/useErrorArray'
import { getErrorInField } from 'utils/commonFunctions'

type DataItem = Record<string, string>

const StepTwo = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )
  const [loadingCreditSafeCheck, setLoadingCreditSafeCheck] =
    useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])
  const [creditSafePersonalNumberError, setCreditSafePersonalNumberError] =
    useState<string>('')
  const [showHelp, setShowHelp] = useState<boolean>(false)

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): JSX.Element | null => {
    const { name, value } = e.target
    dispatch(UpdateUserValue({ name, value }))
    return null
  }

  // const ceoPNR = localStorage.getItem('CEO_PNR')
  const rating = localStorage.getItem('RATING')

  const forms = [
    {
      label: t('StepTwo.Form.PersonalNumber.label'),
      id: 'personNumber',
      name: 'personNumber',
      value: formValues.personNumber,
      type: 'text',
      // placeholder: t('StepTwo.Form.PersonalNumber.Placeholder'),
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 10 || isNaN(Number(e.target.value))) {
          return
        }
        handleOnChange?.(e)
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {},
      enableErrorMsg: true,
      validationType: 'personalNumber',
      errorMsg: ''
    },
    {
      label: t('StepTwo.Form.Name.Label'),
      id: 'name',
      name: 'name',
      value: formValues.name,
      type: 'text',
      // placeholder: t('StepTwo.Form.Name.Placeholder'),
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange?.(e)
      }
    },
    {
      label: t('StepTwo.Form.Mobile.Label'),
      id: 'mobile',
      name: 'mobile',
      value: formValues.mobile,
      type: 'text',
      // placeholder: '000000 000000',
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 10 || isNaN(Number(e.target.value))) {
          return
        }
        handleOnChange?.(e)
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {},
      enableErrorMsg: true,
      validationType: 'mobile'
    },
    {
      label: t('StepTwo.Form.Email.Label'),
      id: 'email',
      name: 'email',
      value: formValues.email,
      type: 'email',
      // placeholder: 'jane@gmail.com',
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange?.(e)
      },
      enableErrorMsg: true
    }
  ]

  useErrorArray({
    errorArray,
    setErrorArray,
    setIsError,
    step: 2
  })

  return (
    <TellitLoader
      loading={loadingCreditSafeCheck}
      loadingText="Kontrollerar kreditupplysning"
    >
      {!showHelp &&
        forms.map((formItem, idx) => (
          <TellitInput
            key={`${idx}_formItem`}
            label={formItem.label}
            id={formItem.id}
            name={formItem.name}
            value={formItem.value}
            alignCenter
            type={formItem.type}
            onChange={formItem.onChange}
            onBlur={formItem.onBlur}
            enableErrorMsg={formItem.enableErrorMsg}
            validationType={formItem.validationType}
            setError={(val) => {
              const isFormError = useValidateForm({
                fieldError: val,
                errorArray,
                name: formItem.name,
                setErrorArray
              })
              setIsError(isFormError)
            }}
            required={formItem.required}
            errorMsg={formItem.errorMsg}
            triggerValidationOnMount={getErrorInField(
              formItem.name,
              errorArray
            )}
          />
        ))}

      {creditSafePersonalNumberError !== '' && (
        <p className="text-center text-error text-[0.8rem] md:text-base-20 font-normal mt-sp3 mb-[-1rem]">
          {creditSafePersonalNumberError}
        </p>
      )}

      {showHelp && (
        <>
          <Header className="px-10">
            <>
              {t('StepOne.CreditsafeError.Label1')}
              <br></br>
              <br></br>
              {t('StepOne.CreditsafeError.Label2')}{' '}
              <span className="text-secondary">020-120 28 82</span>.
            </>
          </Header>
          <div
            className={`self-center ${
              creditSafePersonalNumberError !== '' ? '' : 'mt-6'
            } flex flex-col xl:flex-row flex-wrap justify-center items-center w-full`}
          >
            <NextBtn
              label={t('StepTwo.Button.TryAgain')}
              type="up"
              handleClick={() => {
                // dispatch(UpdateUserValue({ name: 'orgNumber', value: '' }))
                // dispatch(UpdateUserValue({ name: 'companyName', value: '' }))
                setCreditSafePersonalNumberError('')
                setShowHelp(false)
              }}
            />
          </div>
        </>
      )}

      {!showHelp && (
        <div className="self-center flex flex-col md:flex-row flex-wrap1 justify-between items-center w-full md:px-sp150 lg:px-sp50 2xl:px-sp180 footer-nav">
          <BackBtn
            handleClick={() => {
              dispatch(NavigateBackward())
            }}
            customClass="2xl:pl-0"
          />
          <NextBtn
            handleClick={() => {
              setCreditSafePersonalNumberError('')

              // Comment this earlier PNR check after we implement new credisafe check
              // const isPersonNumberMatch = CheckPersonNumber({
              //   personNumber: ceoPNR?.trim(),
              //   comparingNumber: formValues?.personNumber.trim()
              // })
              // if (isPersonNumberMatch) {
              setLoadingCreditSafeCheck(true)
              void handleApiCall({
                urlType: 'checkCreditSafe',
                urlParams: `?orgnr=${formValues.orgNumber}&companyname=${
                  formValues.companyName
                }&pnr=${
                  formValues.personNumber
                }&data=%7B"namn": "${formValues.name.trim()}","mobilnummer": "${formValues.mobile.trim()}","e-post": "${formValues.email.trim()}","rating": ${rating}%7D`,
                cb: (res, state) => {
                  if (state) {
                    if (res?.data !== undefined) {
                      setLoadingCreditSafeCheck(false)
                      dispatch(
                        UpdateUserValue({
                          name: 'companyName',
                          value: res?.data.companyName
                        })
                      )
                      dispatch(
                        UpdateUserValue({
                          name: 'address',
                          value: res?.data.address
                        })
                      )
                      dispatch(
                        UpdateUserValue({
                          name: 'zip',
                          value: res?.data.zip
                        })
                      )
                      dispatch(
                        UpdateUserValue({
                          name: 'city',
                          value: res?.data.town
                        })
                      )
                      localStorage.setItem('RATING', res?.data.rating)
                      dispatch(NavigateForward())
                      dispatch(SetLoading({ name: 'loading', value: true }))
                      void handleApiCall({
                        urlType: 'areaCodes',
                        // setLoading: setAreaCodeLoading,
                        cb: (res: any, state) => {
                          dispatch(
                            SetLoading({
                              name: 'loading',
                              value: false
                            })
                          )
                          if (state) {
                            try {
                              const transformedArray = res?.data?.map(
                                (item: DataItem) => {
                                  const [value, name] = Object.entries(item)[0]
                                  const nameCustom = `${value} - ${name}`
                                  return { value, name: nameCustom }
                                }
                              )

                              const value: any = transformedArray
                              dispatch(
                                SetCardData({
                                  name: 'areaCodes',
                                  value
                                })
                              )
                            } catch (error) {
                              console.log('error333333', error)
                              throw error
                            }
                          } else {
                            if (
                              res.message !== undefined &&
                              res.message !== ''
                            ) {
                              handleToastMessage(res.message)
                            }
                            console.log('error')
                          }
                        }
                      })
                    } else {
                      setShowHelp(true)
                      setLoadingCreditSafeCheck(false)
                      setCreditSafePersonalNumberError(res?.message)
                    }
                  } else {
                    setShowHelp(true)
                    setLoadingCreditSafeCheck(false)
                    setCreditSafePersonalNumberError(res?.message)
                  }
                }
              })
            }}
            disabled={
              formValues.personNumber === '' ||
              formValues.name === '' ||
              formValues.mobile === '' ||
              formValues.email === '' ||
              isError
            }
          />
        </div>
      )}
    </TellitLoader>
  )
}

export default StepTwo
