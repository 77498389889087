// const baseUrl = process.env.REACT_APP_API_URL
const baseUrl = `https://b7dso81b2j.execute-api.eu-north-1.amazonaws.com/prod/order`
// const tellitTechUrl = process.env.REACT_APP_FEDERATED_LOGIN
const tellitTechUrl = `https://b7dso81b2j.execute-api.eu-north-1.amazonaws.com/prod`
// const creditSafeUrl = process.env.REACT_APP_CREDIT_SAFE_URL

type UrlDocType = Record<string, { url: string; type: string }>

const urlDoc: UrlDocType = {
  areaCodes: { url: `${baseUrl}?areacodes`, type: 'get' },
  getNumbers: { url: `${baseUrl}?numbers&areacode=`, type: 'post' },
  save: { url: `${baseUrl}`, type: 'post' },
  federatedLogin: {
    url: `${tellitTechUrl}/grandid_init_login`,
    type: 'get'
  },
  getSession: {
    url: `${tellitTechUrl}/grandid_callback`,
    type: 'post'
  },
  checkCreditSafe: {
    // url: `https://unitybackend-env.eba-ujyppbag.eu-north-1.elasticbeanstalk.com/creditsafe_wrapper.php`,
    url: `${tellitTechUrl}/creditsafe2`,
    type: 'get'
  }
}

export default urlDoc
